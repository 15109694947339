import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Welcome() {
  return (
    <>
      <Container fluid>
        <Row className="justify-content-center align-items-center mt-20 text-center">
          <Col xs={10} sm={8} md={6}>
            <h1 className="text-light container mb-5 text-center">
              ¿Consultor o programador?{" "}
            </h1>
            <div className="">
              <h4 className="text-light  mb-3">
              ¡DESCUBRÍ TU VERDADERO PERFIL EN TECNOLOGÍA!
              </h4>
              <h4 className="text-light">
                Respondé el siguiente test vocacional y enterate si lo tuyo es
                el código o te tira la consultoría en Tecnología.
              </h4>
            </div>
            <div className="text-center">
              <Link to={"/form"}>
                <button className="btn btn-outline-info mt-3">¡Empezar!</button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Welcome;
