import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import emailjs from "emailjs-com";

function Form() {
 
  //Form initial - Start
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [errorMail, setErrorMail] = useState("");
  const [showButton, setShowButton] = useState(false);

  const [formInitial, setFormInitial] = useState(true);
  const [secondForm, setSecondForm] = useState(false);
  const [thirdForm, setThirdForm] = useState(false);

  useEffect(() => {
    setErrorMail("");
    setShowButton(false);
    if (validateMail(mail)) {
      setShowButton(true);
    } else if (mail !== "") {
      setErrorMail("Correo electronico invalido.");
    }
  }, [mail]);

  function handleSubmit(event) {
    event.preventDefault();

    setShowButton(true);
  }

  function validateMail(mail) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail);
  }

  const showData = () => {
    setTimeout(
      () => setFormInitial(false),
      setSecondForm(true),
      scrollToTop(),
      [1000]
    );
  };
  //Form initial - Close

  //Form Questions - Start
  const [score1, setScore1] = useState(1);
  const [score2, setScore2] = useState(1);
  const [score3, setScore3] = useState(1);
  const [score4, setScore4] = useState(1);

  const consultor = 'Consultor';
  const programador = 'Programador';

  const total = score1 + score2 + score3 + score4;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const mostrarPuntaje = () => {
    setTimeout(() => setSecondForm(false), setThirdForm(true), scrollToTop(), [
      1000,
    ]);
    enviarCorreo();
  };

  const answer = () => {
    if (total <= 20) {
      return programador;
    } else {
      return consultor;
    }
  };
  //Form Questions - Close

  //Send mail - Start

  function enviarCorreo() {
    const templateParams = {
      name: name,
      lastName: lastName,
      mail: mail,
      result: answer(),
    };

    emailjs
      .send(
        "service_kyndy54",
        "template_ctmq3c2",
        templateParams,
        "SSWUFv2_J6iy1ME7w"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  }

  return (
    <>
      {formInitial && (
        <Container fluid>
          <Row className="justify-content-center align-items-center vh-100">
            <Col xs={10} sm={8} md={6}>
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="form-label text-light ">Nombre:</label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="off"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div>
                  <label className="form-label text-light mt-2">
                    Apellido:
                  </label>
                  <input
                    autoComplete="off"
                    className="form-control"
                    type="text"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </div>
                <div>
                  <label className="form-label text-light mt-2">
                    Correo electrónico:
                  </label>
                  <input
                    className="form-control"
                    autoComplete="on"
                    type="email"
                    value={mail}
                    onChange={(event) => setMail(event.target.value)}
                  />
                  {errorMail && <div className="text-danger">{errorMail}</div>}
                </div>
                {showButton && (
                  <div className="text-center">
                    <button
                      type="submit"
                      onClick={showData}
                      className="btn btn-outline-info mt-3 "
                    >
                      Siguiente
                    </button>
                  </div>
                )}
              </form>
            </Col>
          </Row>
        </Container>
      )}

      {secondForm && (
        <Container fluid>
          <Row className="justify-content-center align-items-center vh-100">
            <Col xs={10} sm={8} md={6}>
              <div className="text-light container mt-3">
                <p>
                  ¿Cuánto te gusta conversar con los usuarios de los sistemas?
                </p>
                <div className="container">
                  <Slider
                    min={1}
                    max={10}
                    defaultValue={1}
                    onChange={(value) => setScore1(value)}
                  />
                  <div>{score1}</div>
                </div>
                <p>
                  ¿Cuánto te gusta involucrarte en la estrategia de las
                  empresas?
                </p>
                <div className="container">
                  <Slider
                    min={1}
                    max={10}
                    defaultValue={1}
                    onChange={(value) => setScore2(value)}
                  />
                  <div>{score2}</div>
                </div>
                <p>¿Cuánto te gusta trabajar en equipo?</p>
                <div className="container">
                  <Slider
                    min={1}
                    max={10}
                    defaultValue={1}
                    onChange={(value) => setScore3(value)}
                  />
                  <div>{score3}</div>
                </div>
                <p>
                  ¿Te gusta remangarte y hacer o sos más de pensar en las
                  soluciones?
                </p>
                <p className="form-text text-light">
                  * Más cerca del 1 te gusta remangarte, más cerca del 10 te
                  gusta pensar estratégicamente.
                </p>
                <div className="container">
                  <Slider
                    min={1}
                    max={10}
                    defaultValue={1}
                    onChange={(value) => setScore4(value)}
                  />
                  <div>{score4}</div>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-outline-info mt-3 mb-3"
                    onClick={mostrarPuntaje}
                  >
                    ¡Terminé!
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {thirdForm && (
        <>
          {total <= 20 ? (
            <Container fluid>
              <Row className="justify-content-center align-items-center vh-100">
                <Col xs={10} sm={8} md={6}>
                  <div className="text-light text-center">
                    ¡El código corre por tus venas! Lo tuyo es programar. Dejanos tu correo para que podamos contarte de nuestras oportunidades laborales.
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container fluid>
              <Row className="justify-content-center align-items-center vh-100">
                <Col xs={10} sm={8} md={6}>
                  <div className="text-light text-center">
                    Tenés habilidades de comunicación y pensamiento crítico.
                    <div></div>
                    ¡Bienvenido al mundo de la consultoría!
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
}

export default Form;
