import React from "react";
import logo from "./Logo CPA - IT builders.png";


function Logo() {
  return (
    <div className="mt-3 me-3 d-flex justify-content-center" href="#">
      <img src={logo} alt="Logo FERRERE" width={200} height={130}  />
      {/* <div className="logoGreat position-absolute top-0 end-0">
        <img
          src={g}
          width={100} height={170}
          alt="Logo Great Place to Work 2023"
        />
      </div> */}
    </div>
  );
}

export default Logo;
